<template>
  <div id="app">
    <nprogress-container></nprogress-container>
    <div class="logo p-3" ref="logo">
      <div class="name">KSENIYA KAWKO</div>
      <div class="description">Tonmeister / Recording Producer / 3D Audio Engineer</div>
    </div>
    <notifications group="media" position="bottom right"/>
    <div class="navbar-container">
      <nav class="navbar navbar-expand-lg navbar-light px-5 mb-4">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav w-100 justify-content-around">
            <li class="nav-item">
              <router-link class-active="active" class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class-active="active" class="nav-link" to="/portfolio">Portfolio</router-link>
            </li>
            <li class="nav-item">
              <router-link class-active="active" class="nav-link" to="/about">About</router-link>
            </li>
            <li class="nav-item">
              <router-link class-active="active" class="nav-link" to="/immersive">Immersive</router-link>
            </li>
            <li class="nav-item">
              <router-link class-active="active" class="nav-link" to="/contact">Contact</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Language
              </a>
              <LanguageSwitcher/>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="container-fluid">
      <router-view class="router-view"/>
      <div id="scrollTo"></div>
      <div style="max-width: 1400px; margin: auto;" class="d-flex justify-content-center mt-3 mb-3">
        <transition name="fade">
          <div v-if="showImpressumTitle" @click="impressumClick(!impressum)" style="cursor: pointer;" class="h6">Impressum & Datenschutzerklärung</div>
        </transition>
      </div>
      <transition name="slide-fade">
        <vue-custom-scrollbar v-if="impressum" style="max-width: 1400px; margin: auto;">
          <div class="d-flex justify-content-center">
            <div class="h6">Haftung für Inhalte</div>
          </div>

          <div class="contact-description-text position-relative">
            <img src="./assets/img/up-arrow.svg" alt="photo" @click="impressumClick(false)"
                 class="position-absolute up-arrow"
            >
            Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann jedoch keine Gewähr übernommen werden. Der Diensteanbieter ist gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG ist der Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden diese Inhalte selbstverständlich umgehend entfernt.
          </div>

          <div class="h6">
            Haftung für Links
          </div>
          <div class="contact-description-text">
            Dies Seiten dieser Webpräsenz enthalten Links zu externen Webseiten Dritter, auf deren Inhalte keinen Einfluss genommen wird. Deshalb kann für diese fremden Inhalte auch keine Gewähr übernommen werden. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend entfernt.
          </div>

          <div class="h6">
            Urheberrecht
          </div>

          <div class="contact-description-text">
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung

            aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden derartige Inhalte umgehend entfernt.

          </div>
          <div class="h6 mb-3">
            Datenschutzerklärung
          </div>
          <div class="h6">Allgemeine Hinweise</div>
          <div class="contact-description-text">
            Zum Schutz Ihrer Privatsphäre verzichtet diese Webpräsenz vollständig auf die Erhebung von personenbezogenen Daten. Es werden keine Cookies gesetzt, keine tracking Plugins genutzt und es wird auf die Nutzung externer Analysedienstleister verzichtet. Verweise auf externe Medien sind als Weblinks ausgeführt, daher werden beim Laden dieser Seite keine Daten an Dritte weitergeleitet.
          </div>
          <div class="h6">
            Logdateien
          </div>
          <div class="contact-description-text">
            Der Webserver schreibt funktionsbedingt Logdateien, die den Zugriffszeitpunkt und die IP-Adresse des Nutzers protokollieren. Diese Daten werden nur bei Bedarf zur Gewährleistung der Funktion dieser Webpräsenz ausgewertet und nicht an Dritte weitergegeben. Da keine Benutzerkonten zum Einsatz kommen und auf jegliche Form des Trackings verzichtet wird, handelt es sich hierbei nicht um personenbezogene Daten.
          </div>
          <div class="contact-description-text d-flex">
            <span class="ml-auto mr-auto">Angaben gemäß § 5 TMG</span>
          </div>
          <div class="contact-description-text">
            Kseniya Kawko<br>
            Heideckstr. 2<br>
            80637 München<br>
            <a class="text-dark" href="mailto:kseniya.kawko@tonmeister.de">kseniya.kawko@tonmeister.de</a><br>
            <a class="text-dark" href="tel:+4917632161791">+49 17632161791</a>
          </div>
          <div class="contact-description-text">
            Fotos: Kai Neunert (Home), Nicole Arrage (Contact&Impressium), Pavel Sinilo, Sam Hindle, Richardo Morejon, Kseniya Kawko (About)<br>
            Website Entwicklung: Kiryl Matsenko
          </div>
          <img src="./assets/img/up-arrow.svg" alt="photo" @click="impressumClick(false)" class="cursor-pointer mb-3">
        </vue-custom-scrollbar>
      </transition>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from './components/LanguageSwitcher.vue'
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  components: {
    LanguageSwitcher,
    NprogressContainer,
    vueCustomScrollbar
  },
  data() {
    return {
      impressum: false,
      showImpressumTitle: true
    }
  },
  watch:{
    $route () {
      this.impressum = false
      this.showImpressumTitle = true
    }
  },
  methods: {
    impressumClick(val) {
      this.impressum = val
      this.showImpressumTitle = !val
      if (val) {
        setTimeout(() => {
          let el = this.$el.querySelector('#scrollTo')
          el.scrollIntoView({behavior: 'smooth'})
        }, 100)
      }
    }
  }
}
</script>


<style lang="scss">
@font-face {
  font-family: raleway-med;
  src: url('assets/raleway/Raleway-Medium.ttf');
}

@font-face {
  font-family: raleway-semi-bold;
  src: url('assets/raleway/Raleway-SemiBold.ttf');
}

@font-face {
  font-family: raleway-medium-bold-cursive;
  src: url('assets/raleway/Raleway-MediumItalic.ttf');
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity .5s;
}
.fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(60px);
  opacity: 0;
}

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #332631;
}
.up-arrow {
  right: 10rem;
  top: -1.5rem;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    right: 3rem;
  }
}
.logo {
  color: #332631;
}

.name {
  font-size: 2em;
  font-family: raleway-med;
}

.description {
  font-size: 1.1em;
  font-family: raleway-med;
}

.navbar-container {
  width: 100%;
}
.navbar-nav{
  color: #332631;
}
.navbar-container nav {
  background: #EEFAFB;
  max-width: 1400px;
  margin: auto;
}
.navbar-light .navbar-nav .nav-link{
  color: #332631!important;
  font-size: 17px;
  border-bottom: 1px solid transparent;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus{
  border-bottom: 1px solid #332631;
}
.container-fluid {
  /*background-color: #EFEBE9;*/
}
.container-fluid .router-view {
  background: #F8F8F8;
  max-width: 1400px;
  margin: auto;
  max-height: 1050px;
}
.contact-description-text {
  text-align: justify;
  font-size: 15px;
  padding: 0 10rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 992px) {
    padding: 0 3rem;
  }
}
</style>
